<template>
  <b-container class="p-0 pt-3" style="background: white">
    <div>
      <b-alert v-model="showAlert" variant="danger" dismissible>
        <b>{{ alertMessage }}</b>
      </b-alert>
    </div>
    <b-overlay
      :show="documentsLoading"
      variant="white"
      style="min-height: 200px"
    >
      <b-row
        v-if="mode === 'list'"
        align-v="center"
        align-h="between"
        class="px-5"
      >
        <b-col sm="12" md="7" lg="6" class="d-flex align-items-center">
          <b-input-group class="search-group">
            <b-input-group-prepend>
              <span class="input-group-text"
                ><b-icon icon="search"></b-icon
              ></span>
            </b-input-group-prepend>
            <b-form-input
              @keyup="debounceDocuments"
              @search="debounceDocuments"
              class="document-search-input pl-1"
              type="search"
              v-model="search.terms"
              placeholder="Try typing a filename or title"
            />
            <b-input-group-append>
              <span class="divider"></span>
              <span class="divider"></span>
              <b-dropdown
                class="dropdown filter-dropdown"
                :boundary="'window'"
                ref="dropdown"
              >
                <template #button-content>
                  <b-icon-funnel class="mr-2" />{{ filterInputLabel }}
                </template>
                <b-dropdown-form class="d-flex flex-column align-items-start">
                  <span style="font-size: 20px; font-weight: 300">Filters</span>

                  <div class="selecter-label">Document Type</div>
                  <multiselect
                    v-model="search.types"
                    :hide-selected="true"
                    :options="types"
                    track-by="type"
                    label="label"
                    :multiple="true"
                    :show-labels="false"
                    placeholder="-- Select an Option --"
                  >
                  </multiselect>

                  <div class="selecter-label">Worker</div>
                  <multiselect
                    v-model="search.workers"
                    :hide-selected="true"
                    :options="workers"
                    track-by="worker_id"
                    label="name"
                    :multiple="true"
                    :show-labels="false"
                    placeholder="-- Select an Option --"
                  >
                  </multiselect>

                  <div class="selecter-label">Created After</div>
                  <b-form-input
                    type="date"
                    ref="date1"
                    value-as-date
                    locale="en"
                    class="date-input"
                    v-model="search.start_date"
                  >
                  </b-form-input>

                  <div class="selecter-label">Created Before</div>
                  <b-form-input
                    type="date"
                    ref="date2"
                    value-as-date
                    locale="en"
                    class="date-input"
                    v-model="search.end_date"
                  >
                  </b-form-input>

                  <div class="d-flex justify-content-end mt-4">
                    <b-button
                      @click="clearFilters"
                      variant="outline-primary"
                      class="avo-basic-btn mr-2"
                      >Clear Filters</b-button
                    >
                    <b-button
                      @click="getDocuments"
                      variant="outline-primary"
                      class="avo-primary-btn"
                      >Apply</b-button
                    >
                  </div>
                </b-dropdown-form>
              </b-dropdown>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          v-if="idType != 'report'"
          sm="12"
          md="5"
          lg="6"
          class="text-right"
        >
          <b-button
            v-b-modal.new-document
            variant="outline-primary"
            class="avo-primary-btn mt-4 mt-md-0"
          >
            <b-icon icon="plus" class="mr-2" />Add Document
          </b-button>
          <document-add
            :idType="idType"
            :id="id"
            @documentAdded="getDocuments"
            @docUploadError="alertUploadError"
          />
        </b-col>
      </b-row>
      <b-row v-else-if="mode === 'inline'">
        <b-col>
          <div class="d-flex justify-content-between px-4">
            <div
              class="label-text"
              style="
                text-decoration: underline var(--blue) 3px;
                text-underline-offset: 1.2rem;
              "
            >
              Documents
            </div>
            <div class="avo-text-light">View all documents</div>
          </div>
          <hr class="w-100" />
        </b-col>
      </b-row>
      <div v-else-if="mode === 'table'" class="document-table">
        <b-row>
          <b-col class="avo-header-text">
            {{ idType == "report" ? "Exports" : "Documents" }}
          </b-col>
          <b-col
            v-if="idType != 'report'"
            sm="12"
            md="5"
            lg="6"
            class="text-right"
          >
            <b-button
              v-b-modal.new-document
              variant="outline-primary"
              class="avo-primary-btn mt-4 mt-md-0"
            >
              <b-icon icon="plus" class="mr-2" />Add Document
            </b-button>
            <document-add
              :idType="idType"
              :id="id"
              @documentAdded="getDocuments"
              @docUploadError="alertUploadError"
            />
          </b-col>
        </b-row>
        <div class="document-table-rows">
          <b-row
            v-for="(document, index) in documents"
            :key="document.document_id"
          >
            <b-col>
              <hr v-if="index > 0" />
              <document-card
                :key="document.document_id"
                :document="document"
                :mode="'table'"
                :idType="idType"
                :id="id"
                :hideDropdown="hideDropdown"
                @refresh="getDocuments()"
                @docNotFoundError="alertDocNotFound"
                @docDownloadError="alertDocError"
              />
            </b-col>
          </b-row>
        </div>
        <div
          v-if="documents.length == 0"
          class="no-documents mb-4"
        >
          No documents
        </div>
        <b-row v-if="showPagination && documents.length >= 0">
          <b-col md="12" class="">
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPageVar"
              v-model="currentPage"
              align="center"
            />
          </b-col>
        </b-row>
      </div>
      <template v-if="mode === 'list'">
        <div class="p-5">
          <b-row>
            <b-col
              class="col-12 col-sm-6 col-md-4 col-lg-3"
              v-for="document in documents"
              :key="document.document_id"
            >
              <document-card
                :key="document.document_id"
                :document="document"
                :mode="'list'"
                :idType="idType"
                :id="id"
                :hideDropdown="hideDropdown"
                @refresh="getDocuments()"
                @docNotFoundError="alertDocNotFound"
                @docDownloadError="alertDocError"
              />
            </b-col>
          </b-row>
        </div>
      </template>
      <template v-else-if="mode === 'inline'">
        <div class="pt-4 pr-4 pl-4">
          <b-row>
            <b-col>
              <document-card
                v-for="document in documents"
                :key="document.document_id"
                :document="document"
                :mode="'inline'"
                :idType="idType"
                :id="id"
                :hideDropdown="hideDropdown"
                @refresh="getDocuments()"
                @docNotFoundError="alertDocNotFound"
                @docDownloadError="alertDocError"
              />
            </b-col>
          </b-row>
        </div>
      </template>
      <div
        v-if="documents.length == 0 && documentsLoading && mode != 'table'"
        class="no-documents"
      >
        Fetching documents
      </div>
      <div
        v-if="documents.length == 0 && !documentsLoading && mode != 'table'"
        class="no-documents"
      >
        No documents found
      </div>
      <b-row v-if="showPagination && documents.length > 0 && mode != 'table'">
        <b-col md="12" class="">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPageVar"
            v-model="currentPage"
            align="center"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<script>
import { make_url, authenticated_request, debounce, alertsMixin } from "../../common.js";
import Multiselect from "vue-multiselect";

import DocumentCard from "./DocumentCard.vue";
import DocumentAdd from "./DocumentAdd.vue";
export default {
  name: "DocumentList",
  components: {
    Multiselect,
    DocumentCard,
    DocumentAdd,
  },
  mixins: [alertsMixin],
  props: ["idType", "id", "perPage", "mode", "hidePagination", "hideDropdown"],
  data() {
    return {
      documents: [],
      paginatedDocuments: [],
      search: {
        terms: "",
        types: [],
        workers: [],
        start_date: "",
        end_date: "",
      },
      search_params: {
        terms: "",
        types: "",
        worker_ids: "",
      },
      types: [],
      workers: [],
      perPageVar: this.perPage,
      currentPage: 1,
      totalRows: "",
      showPagination: !this.hidePagination,
      documentsLoading: true,
    };
  },
  methods: {
    clearFilters: function () {
      this.search.terms = "";
      this.search.types = [];
      this.search.workers = [];
      this.search.start_date = "";
      this.search.end_date = "";
      this.clearFilterParams();
    },
    clearFilterParams: function () {
      this.search_params.terms = "";
      this.search_params.types = "";
      this.search_params.worker_ids = "";
      this.getDocuments();
    },
    getFilterOptions: function () {
      var url;
      if (this.idType == "practice") {
        url = make_url(
          "/reports/practices/documents/" + this.id + "/filters"
        ).toString();
      } else if (this.idType == "report") {
        url = make_url(
          "/reports/reports/documents/" + this.id + "/filters"
        ).toString();
      } else if (this.idType == "preauth") {
        url = make_url(
          "/reports/preauths/documents/" + this.id + "/filters"
        ).toString();
      } else {
        url = make_url(
          "/reports/claims/documents/" + this.id + "/filters"
        ).toString();
      }
      const vm = this;
      const promise = authenticated_request({
        method: "get",
        url: url,
      }).then(function (response) {
        const items = response.data;
        vm.types = items.types;
        vm.workers = items.workers;
        return items || [];
      });
      return promise;
    },
    searchDocuments: function () {
      if (this.search.terms.length > 2 || this.search.terms.length == 0) {
        this.getDocuments();
      }
    },
    getDocuments: function () {
      var params = {
        per_page: this.perPageVar,
        page: this.currentPage,
        search_terms: this.search_params.terms,
        types: this.search_params.types,
        worker_ids: this.search_params.worker_ids,
      };

      if (this.idType == "practice") {
        params.practice_id = this.id;
      } else if (this.idType == "report") {
        params.report_id = this.id;
      } else if (this.idType == "preauth") {
        params.preauth_id = this.id;
      } else {
        params.claim_id = this.id;
      }

      if (this.search.start_date) {
        params.start = this.search.start_date + "T00:00:00+00:00";
      }
      if (this.search.end_date) {
        params.end = this.search.end_date + "T00:00:00+00:00";
      }
      const vm = this;
      const url = make_url("/reports/documents");
      const promise = authenticated_request({
        method: "get",
        url: url,
        params: params,
      })
        .then(function (response) {
          vm.documents = response.data.documents;
          vm.totalRows = response.data.pagination.total;
          return response.data.documents;
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          vm.documentsLoading = false;
        });
      return promise;
    },
    // Error Handlers
    alertDocNotFound() {
      this.setShowAlert(true, "The requested document could not be located.");
    },
    alertDocError() {
      this.setShowAlert(true, 
        "A problem occured while attempting to retrieve this document. Please contact service if this problem persists."
      );
    },
    alertUploadError() {
      this.setShowAlert(true,
        "A problem occured while attempting to upload this document. Please contact service if this problem persists."
      );
    },
  },
  computed: {
    filterInputLabel: function () {
      const count = this.filterCount;
      if (count == 0) {
        return "Add Filters";
      } else {
        return count + " Filter(s)";
      }
    },
    filterCount: function () {
      var count = 0;
      for (const filter in this.search) {
        if (
          (this.search[filter].length ||
            ((filter === "start_date" || filter === "end_date") &&
              this.search[filter])) &&
          filter !== "terms"
        ) {
          count += 1;
        }
      }
      return count;
    },
    refreshDocs: function () {
      return this.$store.state.claims.reloadDocuments;
    },
  },
  watch: {
    currentPage: function () {
      this.getDocuments();
    },
    "search.terms": function (newValues) {
      this.search_params.terms = newValues;
    },
    "search.types": function (newValues) {
      this.search_params.types = newValues.map((obj) => obj.type).join();
    },
    "search.workers": function (newValues) {
      this.search_params.worker_ids = newValues
        .map((obj) => obj.worker_id)
        .join();
    },
    refreshDocs(newState) {
      if (newState) {
        this.getDocuments();
        this.$store.commit("documentsRefreshed");
      }
    },
  },
  async mounted() {
    this.getDocuments();
    this.getFilterOptions();
  },
  created() {
    this.debounceDocuments = debounce(this.searchDocuments, 800);
  },
};
</script>

<style scoped>
.document-table {
  border: 1px solid #c9d2df;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
}
.document-table-rows {
  min-height: 500px;
}

/* Styling the search bar group */
::v-deep .search-group {
  position: relative;
  border: 1px solid #c9d2df;
  border-radius: 5px;
  max-width: 650px;
}
::v-deep .document-search-input,
.input-group-text {
  height: 50px;
  border: none;
  border-radius: 5px;
  background: white;
  color: #9ca3ad;
}
::v-deep .document-search-input::placeholder {
  color: #9ca3ad;
  font-size: 12px;
  opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 5px;
  color: #9ca3ad;
  background: white;
  font-size: 12px;
  height: 50px;
  padding-right: 35px;
  min-width: 150px;
  text-align: left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active,
::v-deep .btn-secondary:not(:disabled):not(.disabled).active,
::v-deep .show > .btn-secondary.dropdown-toggle {
  color: #519eff !important;
  background-color: white !important;
}
::v-deep .b-dropdown .btn-secondary:focus {
  box-shadow: none !important;
}
.divider {
  display: inline-block;
  width: 0;
  height: 20px;
  margin: auto;
  border-right: 1.8px solid #d2d8e2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
  padding: 20px 30px 20px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
  width: 100.4%;
  right: -1px !important;
  left: auto !important;
  top: 45px !important;
  transform: none !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px 16px 48px #1617182b !important;
}
.selecter-label {
  margin-top: 0.8rem;
}
.no-documents {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
  /* border: 1px solid #E2E4EB; */
  border-radius: 0px 0px 5px 5px;
  border-top: 0px;
  padding-top: 0px;
  height: 50px;
}
</style>