<template>
    <b-card v-if="mode != 'table'" class="shadow" >
        <div v-if="!hideDropdown" class="d-flex flex-wrap justify-content-end">
            <!--b-badge v-if="document.type_label != 'unknown'">#{{document.type_label}}</b-badge-->
            <b-dropdown class="avo-action-dropdown" no-caret style="min-width:none" :right="true">
                <template #button-content>
                <b-icon icon="three-dots"></b-icon>
                </template>
                <b-dropdown-item @click="viewEditTitleModal()">Rename</b-dropdown-item>
                <b-dropdown-item v-if="document.filename ? (document.filename.split('.').pop() == 'pdf') : false" @click="getDownloadUrl(true)">Preview</b-dropdown-item>
                <b-dropdown-item @click="deleteDocument()">Delete</b-dropdown-item>
            </b-dropdown>
            <b-modal :id="'rename-modal-' + document.document_id" hide-footer hide-header>
                <div class="avo-header-text" style="text-align:center">
                    Rename document
                </div>
                <hr>
                <b-form-group
                    id="document-title"
                    label="New title:">
                    <b-form-input v-model="new_title"></b-form-input>
                </b-form-group>
                <div class="mt-4" style="text-align:center">
                    <b-button @click="submitNewTitle()" class="avo-primary-btn mr-2">Submit</b-button>
                    <b-button @click="closeEditTitleModal()" class="avo-basic-btn ml-2">Close</b-button>
                </div>
            </b-modal>
            
        </div>
        <div class="doc-card-body" style="min-height:180px;">
            <div class="d-flex flex-column align-items-center w-100">
                <div v-if="document.title" class="doc-title mb-3">{{document.title}}</div>
                <br v-else >
                <b-icon :icon="getFileIcon(document.filename)" font-scale="3" class="mb-2" />
                <!-- <a href="#" @click="getDownloadUrl" style="font-size:14px;">{{document.filename}}</a> -->
                <div class="filename-link w-100">
                    <a role="button" @click="getDownloadUrl()">{{document.filename}}</a>
                </div>
                <div>
                    <b-badge v-if="document.type_label != 'unknown'">#{{document.type_label}}</b-badge>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-end align-items-center created-text">
                <div>Created by {{document.name}}</div>
                <div>{{ new Date(document.created).toLocaleString("en-US", {month: 'numeric', day: 'numeric', year: 'numeric'}) }}</div>
            </div>
        </div>
    </b-card>
    <b-row v-else class="mt-3">
        <b-col cols=1>
        <b-icon :icon="getFileIcon(document.filename)" font-scale="2" />
        </b-col>
        <b-col :cols="!hideDropdown ? 8 : 10" v-if="document.title" class="d-flex flex-column" style="overflow:hidden;text-overflow: ellipsis;text-align:left" >
            <div class="doc-title" style="text-align:left;overflow:hidden;text-overflow: ellipsis;">
                {{document.title}}
            </div>
            <div class="avo-text-light">
                <span v-if="document.filename && document.filename.includes('.')">
                    {{document.filename.split('.').pop()}} <span class="ml-1 mr-1"> | </span> 
                </span>
                Uploaded on {{new Date(document.created).toLocaleString("en-US", {month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'})}}
            </div>
        </b-col>
        <b-col cols=1 class="filename-link">
            <a role="button" @click="getDownloadUrl()" ><b-icon icon="download" class="mt-2" font-scale="2"/></a>
        </b-col>
        <b-col v-if="!hideDropdown" cols=2>
            <b-dropdown class="avo-action-dropdown" no-caret style="min-width:none" :right="true">
                <template #button-content>
                <b-icon icon="three-dots"></b-icon>
                </template>
                <b-dropdown-item @click="viewEditTitleModal()">Rename</b-dropdown-item>
                <b-dropdown-item v-if="document.filename ? (document.filename.split('.').pop() == 'pdf') : false" @click="getDownloadUrl(true)">Preview</b-dropdown-item>
                <b-dropdown-item v-if="idType == 'report'" @click="viewReportDocumentAssignmentModal()">Manage Assignments</b-dropdown-item>
                <b-dropdown-item @click="deleteDocument()">Delete</b-dropdown-item>
            </b-dropdown>
            <b-modal :id="'rename-modal-' + document.document_id" hide-footer hide-header>
                <div class="avo-header-text" style="text-align:center">
                    Rename document
                </div>
                <hr>
                <b-form-group
                    id="document-title"
                    label="New title:">
                    <b-form-input v-model="new_title"></b-form-input>
                </b-form-group>
                <div class="mt-4" style="text-align:center">
                    <b-button @click="submitNewTitle()" class="avo-primary-btn mr-2">Submit</b-button>
                    <b-button @click="closeEditTitleModal()" class="avo-basic-btn ml-2">Close</b-button>
                </div>
            </b-modal>
        </b-col>
    </b-row>
</template>

<script>
import { make_url, authenticated_request, unauthenticated_request } from '../../common.js'


export default {
    name: 'DocumentCard',
    props: [
        'document',
        'mode',
        'idType',
        'id',
        'hideDropdown'
    ],
    components: {},
    data(){
      return {
          new_title: "",
          documentPreview: null,
        }
    },
    methods: {
        getFileIcon(filename) {
            const extension = filename ? filename.split('.').pop() : '';
            const spreadsheets = ['xls', 'xlsx', 'ods', 'xlsm', 'csv']
            const word_docs = ['doc', 'docx']
            const docs = ['pdf', 'pages', 'txt', 'odt']
            const presentations = ['pptx', 'ppt', 'odp']
            const images = ['jpg', 'jpeg', 'png', 'svg', 'tiff']

            if (spreadsheets.includes(extension)){
                return 'file-earmark-spreadsheet'
            }
            else if (word_docs.includes(extension)){
                return 'file-earmark-word'
            }
            else if (docs.includes(extension)){
                return 'file-earmark-text'
            }
            else if (presentations.includes(extension)){
                return 'file-earmark-slides'
            }
            else if (images.includes(extension)){
                return 'file-earmark-image'
            }
            return 'file-earmark'
        },
        getDownloadUrl(preview_file) {
            const vm = this;
            const url = make_url("/documents/" + this.document.document_id + "/download");
            const promise = authenticated_request({
                method: "get",
                url: url,
            }).then(function(response){
                const fullUrl = new URL(response.data, process.env.VUE_APP_API_URL);
                let storage_location = 'local';
                //TODO: "includes('amazonaws')" won't properly differentiate between S3 and backend server 
                // because staging site actually has 'amazonaws'. 
                // However we shouldn't need authenticated_request for local storage either.
                if (String(response.data).includes('amazonaws')){ 
                    console.log('Received a url for AWS S3 storage');
                    storage_location = 'aws';
                } else {
                    console.log('Received a url for local storage');
                }
                vm.downloadFile(fullUrl, storage_location, preview_file);
            }, function(error){
                console.log(error)
            });
            return promise;
        },
        downloadFile(url, storage_location = 'local', preview_file) {
            let request_method;
            if (storage_location == 'aws') {
                request_method = unauthenticated_request;
            } else {
                request_method = authenticated_request;
            }
            const vm = this;
            const promise = request_method({
                method: "get",
                url: url,
                responseType: 'blob'
            }).then(function(response){
                var url;
                if (!preview_file){
                    url = window.URL.createObjectURL(new Blob([response.data]));
                }
                else{
                    url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                }
                const link = document.createElement('a');
                link.href = url;
                if (!preview_file){
                    link.setAttribute('download', vm.document.filename);
                }
                else{
                    link.setAttribute('target', '_blank');
                }
                document.body.appendChild(link);
                link.click();
            }).catch(function(error){
                if(error.response.status == 404) {
                    vm.$emit('docNotFoundError');
                }
                else {
                    vm.$emit('docDownloadError')
                }
            });
            return promise;
        },
        viewEditTitleModal(){
            this.new_title=this.document.title;
            this.$bvModal.show('rename-modal-'+this.document.document_id);
        },
        closeEditTitleModal(){
            this.new_title="";
            this.$bvModal.hide('rename-modal-'+this.document.document_id);
        },
        submitNewTitle(){
            const vm = this;
            const url = make_url("/documents/" + this.document.document_id);
            const promise = authenticated_request({
                method: "put",
                url: url,
                data: {
                    'title': this.new_title
                }
            }).then(function(response){
                if (response){
                    //change title differently probably
                    vm.document.title = vm.new_title;
                    vm.closeEditTitleModal();
                    vm.$store.commit("refreshActivityLog");
                }
            }, function(error){
                console.log(error)
            });
            return promise;
        },
        deleteDocument(){
            const vm = this;
            var url_string = "/documents/" + this.document.document_id;
            if (this.idType == 'practice'){
                url_string = url_string + "/practice"
            }
            else if (this.idType == 'preauth'){
                url_string = url_string + "/preauth"
            }
            else if (this.idType == 'report'){
                url_string = url_string + "/report"
            }
            else{
                url_string = url_string + "/claim/" + this.id
            }
            const url = make_url(url_string);
            const promise = authenticated_request({
                method: "delete",
                url: url,
            }).then(function(response){
                if (response){
                    vm.$emit('refresh');
                    vm.$store.commit("refreshActivityLog");
                }
            }, function(error){
                console.log(error)
            });
            return promise;
        },
        viewReportDocumentAssignmentModal(){
            this.$store.commit("reports/setReportDocumentAssignmentID", this.document.document_id);
            this.$bvModal.show('report-document-assignments');
        }
    }
}
</script>

<style scoped>
.card {
    margin-bottom: 24px;
    border-radius: 15px;
    border: 1px solid #E6EEF9;
}
.doc-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width:100%;
}
.card-body {
    padding: 10px 10px 20px 10px;
}
.badge {
    padding:5px;
    border-radius: 5px;
    margin: 4px;
    background: #E6EEF9 0% 0% no-repeat padding-box;
    color: var(--gray);
    font-size: 11px;
    font-weight:400;
}
.doc-title {
    text-align: center;
    font-weight:600;
    font-size:14px;
}
.filename-link {
    color: var(--blue);
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
}
.filename-link a {
    text-overflow:ellipsis;
}
.created-text {
    font-weight:300;
    font-size:12px;
}
.modified-text {
    color: var(--gray);
    font-weight:300;
    font-size:10px;
}
</style>