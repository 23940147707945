<template>
<b-modal @hide="resetModal" id="new-document" title="New Document" no-close-on-backdrop hide-footer size="md">
<div>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>You must select a document to continue.</b>
    </b-alert>
</div>
<div class="p-3">
    <b-form @submit="onSubmit">
        <b-form-group>
            <b-form-file
            v-model="file"
            :state="Boolean(file)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            ></b-form-file>
            <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div>

        </b-form-group>
        <b-form-group
            id="document-title"
            label="Title:">
            <b-form-input v-model="document.document_title"></b-form-input>
        </b-form-group>
        <!-- Wireframe comments say that title should be only field for practice documents -->
        <b-form-group
            v-if="idType != 'practice'"
            id="document-type"
            label="Type:">
            <b-form-select v-model="document.document_type" :options="types">
                <template #first>
                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>
        <div class="text-right">
            <b-button @click="onSubmit" class="document-submit-btn">Save</b-button>
        </div>
    </b-form>
</div>
</b-modal>
</template>

<script>
import { make_url, authenticated_request, unauthenticated_request } from '../../common.js'

export default {
    name: 'DocumentAdd',
    components: {},
    props: ['idType', 'id'],
    data() {
        return {
            document: {
                document_type: null,
                document_title: null,
            },
            showAlert: false,
            file: null
        }
    },
    
    computed: {
        types() {
            return this.$store.state.claims.documentTypes;
        }
    },
    
    methods: {
        onSubmit() {
            if(this.isEmpty()) {
                this.showAlert = true;
                return
            }
            var url;
            if (this.idType == "practice"){
                url = make_url("/practices/" + this.id + "/documents");
            }
            else if (this.idType == "preauth"){
                url = make_url("/preauths/" + this.id + "/documents");
            }
            else{
                url = make_url("/claims/" + this.id + "/documents");
            }
            const vm = this;
            const promise = authenticated_request({
                method: "post",
                url: url,
                data: {
                    title: vm.document.document_title,
                    type: vm.document.document_type,
                    filename: vm.file.name
                }
            }).then(function(response){
                const items = response.data;
                vm.uploadDocument(items.post_url, items.post_params);
            }, function(error){
                console.log(error)
                vm.$emit('docUploadError');
            });
            return promise;
        },
        uploadDocument(path, params){
            const vm = this;
           
            var bodyFormData = new FormData();
            // TODO: We will get different params if we use local file upload or S3, should find a better way to handle this
            const keys = ['from', 'token', 'key', 'policy', 'AWSAccessKeyId', 'signature']
            for (let key of keys) {
                console.log('Checking ' + key)
                if (key in params) {
                    console.log('Got another key: ' + key);
                    bodyFormData.append(key, params[key]);
                }
            }
            bodyFormData.append('file', vm.file)

            const fullUrl = new URL(path, process.env.VUE_APP_API_URL)
            const promise = unauthenticated_request({
                method: "post",
                url: fullUrl,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: bodyFormData
            }).then(function(){
                vm.$emit('documentAdded');
                vm.showAlert = false;
                vm.$bvModal.hide('new-document');
                vm.$store.commit("refreshActivityLog");
            }).catch(function(error) {
                console.log(error.response);
                vm.$emit('docUploadError');
            });
            return promise;
        },
        resetModal(){
            this.document = {
                document_type: null,
                document_title: null,
            }
            this.file = null
        },
        isEmpty() {
            return Boolean(!this.file)
        }
    
    },
    mounted() {
        if (this.types == null) {
            console.log("Document types not loaded yet");
            this.$store.dispatch("fetchDocumentTypesMap");
        }
    }
}
</script>

<style scoped>

.alert {
    background: #FDEEED 0% 0% no-repeat padding-box;
    border: 1px solid var(--red);
    border-radius: 5px;
    text-align: center;
    letter-spacing: 0px;
    color: var(--red);
}
.document-submit-btn {
    height:40px;
    width:113px;
    border: 2px solid var(--blue);
    border-radius: 5px;
    background: white;
    color: var(--blue);
    font-size: 14px;
}
.document-submit-btn:hover {
    background: #EEF1FA 0% 0% no-repeat padding-box;
}
.document-submit-btn:active {
    border: none !important;
    box-shadow: none !important;
    background: var(--blue) 0% 0% no-repeat padding-box !important;
    color: white !important;
}
</style>